<template>
  <div style="background-color:#FAFAFA !important">
    <!--<Loader/>  LOADER QUE SIRVE -->
    <div class="wrapper">
      <SidebarStyle class="sidebar_custom_size" variant='' v-if="$store.getters.usuario.role_id!=3"/>
      <HeaderStyle1/>
      
        <div class="content-page" >
          <transition name="router-anim">
            <router-view/>
          </transition>
        </div>
     
    </div><!--wrapper -->
    <FooterStyle />
  </div>
</template>
<script>
  // import Loader from '@/components/loader/Loader'
import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
import HeaderStyle1 from "@/components/partials/backend/HeaderStyle/HeaderStyle1";
import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";
export default {
  name: 'Layout-2',
  components: {
    HeaderStyle1,
    FooterStyle,
    SidebarStyle,
   // Loader
  }, 
    beforeCreate() {
    this.$store.dispatch("fetchUsuario");
  },
  
}
</script>


<style lang="css" scoped>

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 1367px) {
  .sidebar_custom_size {
    max-width: available;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 1366px) {
  .sidebar_custom_size {
    max-width: 11%
  }
}

</style>
