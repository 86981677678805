<template>
<div :class="'iq-sidebar sidebar-default ' + variant">
    
    <div class="iq-sidebar-logo d-flex align-items-end justify-content-center">
         <a href="#" class="header-logo">
            <img src="@/assets/images/Aamarillo.png" class="img-fluid rounded-normal light-logo" alt="logo">
            <img src="@/assets/images/logo-dark.png" class="img-fluid rounded-normal d-none sidebar-light-img" alt="logo">
           <span class="texto-logo-nivelando">nivelAndo</span>
        </a>
        <div class="side-menu-bt-sidebar-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="miniSidebar">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" @load="miniSidebar"/>
                </svg>
        </div>
    </div>

    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar"  >
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="side-menu" >

                <div
                v-for="(result, index ) in resultSet"
                :key="index"
                >
                  <!--     menu padre Aprende-->
                  <!--   <li :class="( checkActive(`${result['route_name']}`) || checkActive(`${result['route_name']}`) ) ? 'sidebar-layout active' : 'sidebar-layout'">
                       <router-link :to= "{name:`${result['route_name']}`}" class="svg-icon">
                              <i class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journals" viewBox="0 0 16 16"><path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z"/><path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z"/></svg>
                             </i>
                           <span class="ml-2 text-white">  {{  result["text"] }}  </span>
                         </router-link>
                    </li>-->

             <div v-for=" (res, j) in result[`children`]" :key="j"  >
								<li class="sidebar-layout">
                         <router-link :to="`${res.link}`" class="svg-icon"  :style="( checkActive(`${res.route_name}`)   ? 'background-color: rgba(255, 255, 255, 0.08);border-radius: 5px;' : '' ) " >

                                     <b-img  class="icono_espacio_izq" :src="res.icon" alt="aqui va una descripción de esta sección"></b-img>
                                      <span class="ml-2 text-white text-menu" >
                                          {{  res.text }}
                                       </span>
                                    </router-link>


                                </li>
                            </div>

<!--
                            <li :class="checkActive('Cursosall') ? 'sidebar-layout active' : 'sidebar-layout' ">
                                <router-link :to="{name :'Cursosall'}" class="svg-icon">
                                    <i class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="18" 
                                            height="18"
                                            class="h-6 w-6"  
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span class="ml-2">Cursos ej</span>
                                </router-link>
                            </li>       
-->
                    </div>


<!--
                             <li :class="( checkActive('Cursosall') ? 'sidebar-layout active' : 'sidebar-layout' )">
                                <router-link :to="{name :'Cursosall'}" class="svg-icon">
                                  <b-img  src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/iconos/cursos2.svg" alt=""></b-img>
                                    <span class="ml-2">Cursos ej</span>
                                </router-link>
                            </li>
-->


            </ul>
        </nav>
    </div>



</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  props:{
     items: Array,
     variant: {type: String}
  },
  data () {
    return{
      ruta_actual        : this.$route.name,
        indice: 0,
      resultSet: [],
      homeurl:'',
      dashboards:['layout.dashboard1','layout.dashboard2','layout.dashboard3','layout.customer','layout.product','layout.order','layout.calendar'],
      app:['app.chat', 'app.todo'],
      charts:['chart.amchart','chart.apexchart','chart.highchart','chart.morrischart',],
      user:['app.user-profile','app.user-add','app.user-list'],
      ui:['Ui.avatars','Ui.alerts','Ui.badges','Ui.breadcrumbs','Ui.buttons','Ui.button-groups','Ui.boxshadows','Ui.colors','Ui.cards','Ui.carousels','Ui.grids','Ui.helper-classes','Ui.images','Ui.list-groups','Ui.media-objects','Ui.modals','Ui.notifications'
      ,'Ui.paginations','Ui.popovers','Ui.progressbars','Ui.typographys','Ui.tabs','Ui.tooltips','Ui.Embed-videos'],
      auth:['auth.login','auth.register','auth.recover-password','auth.confirm-mail','auth.lock-screen'],
      pricing:['price.pay','price.pay2'],
      icon:['icon.dripicon', 'icon.fontawsome','icon.lineawsome','icon.remixicon',],
      error:['error.404','error.500'],
      formControls:['controls.form-checkbox','controls.form-layout','controls.form-input','controls.form-radio','controls.form-switch','controls.form-textarea','controls.form-validation',],
      formWidget:['widget.form-datepicker','widget.form-file-uploader','widget.form-quill',],
      table:['table.basic-table','table.data-table','table.edit-table',],
      timeline:['time.line','time.line1'],
      extraPages:[ 'price.pay','pages.maintenance','pages.comingsoon','pages.invoices','pages.subscribers','pages.faq','pages.blank-page','pages.icon'],
      pages:[]
    }
  },
  mounted () {
      this.getMenu();
    core.SmoothScrollbar()
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    if (sidebar !== null) {
         this.variant=''
        switch (sidebar) {
            case "0":
                this.variant ='sidebar-dark'
            break;
            case "1":
                this.variant ='sidebar-light'
            break;
            default:
                this.variant =''
                break;
        }
    }
  },
    destroyed () {
        core.SmoothScrollbar()
    },
    computed : {
        ...mapGetters({
            logo:'darklogo',
            sidelogo:'logo',
        })
    },
    methods: {
        checkActive(route) {
            if (this.$route.name == route) {
               // console.log('this.$route.name'+ this.$route.name);
                return true;
            }else{
              return false;
            }
            /*if (route.includes(this.$route.name)) {
              //  console.log('this.$route.name 2 if'+ this.$route.name);
                return true
            }*/
        },
        miniSidebar(){
            core.triggerSet()
        },
        showCollapse(collapseId) {
            document.getElementById(collapseId).closest('li').classList.add('active')
        },
        hideCollapse() {
            const list = document.querySelector('.iq-sidebar-menu').querySelectorAll('.submenu')
            Array.from(list, elem => {
                if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
                    elem.closest('.active').classList.remove('active')
                }
            })
        },
       async getMenu(){
         //   console.log('env working')
        /*   console.log(process.env.VUE_APP_URL)
            console.log(process.env.VUE_APP_API_URL)
            console.log(process.env.VUE_APP_TITLE)*/

            const url       = `/api/menu/get-menu`;
            const response  = await this.$api.get(url);

            let {menus} = response.data;

            this.resultSet = menus;
           // console.log( 'resultSet' );
          //  console.log( this.resultSet );
          //   console.log( this.resultSet[0].icon )
          // console.log( this.resultSet[0].children[0].link );
        }
    },
}
</script>

<style lang="css" scoped>

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 1367px) {
  .texto-logo-nivelando {
    font-size: 22px;
  }
  .text-menu{
    font-size: 16px;
  }

  .icono_espacio_izq{
    margin-left: 0.5rem !important;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 1366px) {
  .texto-logo-nivelando, .text-menu {
    font-size: 14px;
  }

}

</style>